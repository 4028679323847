import { safeFetchAPI } from '@/api';
import { utils } from '@/helpers';
import { Survey, SurveyCleaned, SurveyQuestionInsight, SurveyWithInsights } from '@/types';
import { create } from 'zustand';

type SurveysStoreState = {
	loaded: boolean;
	surveyIDs: string[];
	surveyEntries: Record<string, SurveyCleaned>;
	insightEntries: Record<string, SurveyQuestionInsight[]>;

	insightsID: string | undefined;
};

type SurveyStoreActions = {
	loadSurveys: () => Promise<Record<string, SurveyCleaned>>;
	setArchivedStatus: (surveyID: string, archived: boolean) => void;

	editCallback: (surveyID: string) => void;
	setEditCallback: (callback: (surveyID: string) => void) => void;

	setInsightsID: (insightsID: string | undefined) => void;
	getInsightsSurvey: () => SurveyCleaned | undefined;

	getSurvey: (surveyID: string, uid: string, isImpression: boolean) => Promise<SurveyCleaned | undefined>;
};

type SurveysStore = SurveysStoreState & SurveyStoreActions;

export const useSurveysStore = create<SurveysStore>((set, get) => ({
	loaded: false,
	surveyIDs: [],
	surveyEntries: {},
	insightEntries: {},
	insightsID: undefined,

	editCallback: () => {},
	setEditCallback(callback) {
		set({ editCallback: callback });
	},

	setInsightsID(insightsID) {
		set({ insightsID: insightsID });
	},

	getInsightsSurvey() {
		const { insightsID, surveyEntries } = get();
		if (!insightsID) return undefined;
		return surveyEntries[insightsID];
	},

	async loadSurveys() {
		const [surveysList, error] = await safeFetchAPI<SurveyWithInsights[]>('/survey/list/:uid');

		if (error) {
			utils.toaster?.addToast?.({
				placement: 'top',
				message: error || 'Error loading surveys',
				type: 'danger',
			});
			return {};
		}

		const surveyEntries: Record<string, SurveyCleaned> = {};
		const insightEntries: Record<string, SurveyQuestionInsight[]> = {};

		const surveyIDs = (surveysList ?? [])
			.map((survey_insight) => {
				const survey = cleanSurvey(survey_insight);
				const insights = survey_insight.insights || [];
				if (survey.id) {
					surveyEntries[survey.id] = survey;
					insightEntries[survey.id] = insights;
				}
				return survey.id;
			})
			.filter(Boolean) as string[];

		set({ surveyEntries, insightEntries, surveyIDs, loaded: true });
		return surveyEntries;
	},

	async getSurvey(surveyID, uid, isImpression) {
		const { surveyEntries } = get();

		if (!surveyEntries[surveyID]) {
			const endpoint = `${location.origin.replace('lab-dev', 'lab')}/survey/${surveyID}/${uid}?impression=${isImpression || false}`;
			const [survey, error] = await safeFetchAPI<SurveyWithInsights>(endpoint);
			if (error || !survey) {
				utils.toaster?.addToast?.({
					placement: 'top',
					message: error || 'Error loading survey',
					type: 'danger',
				});
				return;
			}
			const cleanedSurvey = cleanSurvey(survey);

			surveyEntries[surveyID] = cleanedSurvey;
			set({ surveyEntries });

			return cleanedSurvey;
		}

		return surveyEntries[surveyID];
	},

	async setArchivedStatus(surveyID, archived) {
		const [_, error] = await safeFetchAPI(`/survey/status/:uid/${surveyID}/${archived || false}`, {
			method: 'POST',
		});
		if (error) return;

		const { surveyEntries } = get();
		const survey = surveyEntries[surveyID];
		if (!survey) return;
		survey.archived = archived ?? false;
		set({ surveyEntries: { ...surveyEntries, [surveyID]: survey } });
	},
}));

function cleanSurvey(survey_insight: SurveyWithInsights | Survey | SurveyCleaned): SurveyCleaned {
	const survey: Survey = (survey_insight as SurveyWithInsights)?.survey || (survey_insight as Survey);
	const insights = (survey_insight as SurveyWithInsights).insights || [];

	const cleaned = survey as SurveyCleaned;
	// Highest insights.totalCompletions value is the total submissions
	const highestInsight = (insights || []).reduce((p, insight) => {
		if (insight.totalCompletions > p) return insight.totalCompletions;
		return p;
	}, 0);

	const submissions = cleaned?.submissions || highestInsight || 0;
	const lastTS =
		submissions > 0 ? Math.max(...(insights || []).map((insight) => (insight.lastSubmission ? new Date(insight.lastSubmission).getTime() / 1000 : 0))) : 0;
	const lastSubmission = cleaned?.lastSubmission || lastTS;

	return {
		...survey,
		visits: (survey_insight as SurveyWithInsights)?.visits || 0,
		sends: (survey_insight as SurveyWithInsights)?.sends || 0,
		lastSubmission,
		submissions,
	};
}
