import { safeFetchAPI } from '@/api';
import { userUtils, utils } from '@/helpers';
import { Setter } from '@/legacy-types';
import { Survey, SurveyContentAndDesign } from '@/types';
import { clone, isEqual, merge } from 'lodash';
import { create } from 'zustand';
import { DEFAULT_STORE_ID } from '../signup-forms/useSignupBuilderStore';

export interface SurveyBuilderStoreState {
	loading: boolean;
	currentStoreID: number;
	survey?: Partial<Survey>;
	ogSurvey?: Partial<Survey> | undefined;
}

interface EditingTemplateActions {
	setStoreID: (storeID: number | string | undefined) => void;
	setSurvey: (template?: Setter<Partial<Survey | undefined>>) => void;
	setOgSurvey: (template?: Partial<Survey>) => void;

	hasChanged: () => boolean;

	getStoreSettings: (storeID?: number, merge?: boolean) => Partial<SurveyContentAndDesign> | undefined;
	setStoreSettings: (next: Setter<Partial<SurveyContentAndDesign> | undefined>, storeID?: number) => void;
	clearStoreSettings: (storeID?: number) => void;

	saveSurvey: () => Promise<Survey | undefined>;
	reset: () => void;
}

export type SurveyBuilderStore = SurveyBuilderStoreState & EditingTemplateActions;

const DEFAULT_SURVEY_ID = '-1';

const initialState: SurveyBuilderStoreState = {
	loading: true,
	survey: undefined,
	currentStoreID: DEFAULT_STORE_ID,
	ogSurvey: undefined,
};

export const useSurveyBuilderStore = create<SurveyBuilderStore>((set, get) => ({
	...initialState,

	hasChanged: () => {
		const { survey, ogSurvey } = get();
		return !isEqual(survey, ogSurvey);
	},

	setStoreID: (storeID) => {
		const numStoreID = Number(storeID);
		set({ currentStoreID: numStoreID || DEFAULT_STORE_ID });
	},

	setOgSurvey: (template?: Partial<Survey>) => {
		set({ ogSurvey: template });
	},

	setSurvey: (editingTemplate) => {
		const currEditingTemplate = get().survey;
		const nextEditingTemplate = typeof editingTemplate === 'function' ? editingTemplate(currEditingTemplate) : editingTemplate;
		if (userUtils.debugMode()) {
			console.log('--------------------------------');
			console.log('survey', nextEditingTemplate);
			console.log('--------------------------------');
		}
		set({ survey: nextEditingTemplate, loading: false });
	},

	getStoreSettings(storeID, noMerge = false) {
		const { survey, currentStoreID } = get();
		const useStoreID = storeID ?? currentStoreID ?? DEFAULT_STORE_ID;
		const storeSettings = survey?.settingsMap?.[useStoreID];

		if (noMerge || useStoreID === DEFAULT_STORE_ID) {
			return storeSettings;
		}

		const globalSettings = survey?.settingsMap?.[DEFAULT_STORE_ID];
		return merge({}, globalSettings, storeSettings);
	},

	setStoreSettings(next, storeID) {
		const { survey, currentStoreID } = get();
		const useStoreID = Number(storeID ?? currentStoreID ?? DEFAULT_STORE_ID);

		const currStoreSettings = survey?.settingsMap?.[useStoreID];
		const nextStoreSettings: Partial<SurveyContentAndDesign> | undefined = typeof next === 'function' ? next(currStoreSettings) : next;

		if (nextStoreSettings === undefined) {
			return;
		}

		set({
			survey: {
				...survey,
				settingsMap: { ...survey?.settingsMap, [useStoreID]: nextStoreSettings },
			},
		});
	},

	clearStoreSettings(storeID) {
		const { survey, currentStoreID } = get();
		const useStoreID = Number(storeID ?? currentStoreID ?? DEFAULT_STORE_ID);

		if (useStoreID === DEFAULT_STORE_ID) {
			// Reset all stores
			set({ survey: { ...survey, settingsMap: {} } });
			return;
		}

		if (!survey?.settingsMap?.[useStoreID]) {
			return;
		}

		const settingsMap = { ...survey?.settingsMap };
		delete settingsMap[useStoreID];

		set({ survey: { ...survey, settingsMap } });
	},

	saveSurvey: async () => {
		set({ loading: true });

		const { survey } = get();

		const isCopying = window.location.search.includes('copy');
		const isCreating = !survey?.id || survey?.id === DEFAULT_SURVEY_ID || isCopying;
		if (isCreating || isCopying) {
			// biome-ignore lint/performance/noDelete: No performance impact
			delete survey?.id;
		}

		type UpsertResponse = { survey: Survey; op: 'create' | 'update' };
		const endpoint = isCreating ? '/survey/upsert/:uid' : `/survey/upsert/upsert/:uid/${survey?.id}`;
		const [response, error] = await safeFetchAPI<UpsertResponse>(endpoint, {
			method: 'POST',
			payload: survey,
		});

		const { survey: newSurvey, op } = response || {};

		if (error) {
			set({ loading: false });
			utils.toaster.addToast?.({
				message: error,
				type: 'danger',
				placement: 'top',
			});
			return newSurvey;
		}

		if (isCopying) {
			const url = new URL(window.location.href);
			url.searchParams.delete('copy');
			window.history.pushState({}, '', url.toString());
		}

		set({ loading: false, survey: newSurvey, ogSurvey: clone(newSurvey) });
		utils.toaster.addToast?.({
			message: op === 'create' ? 'Survey created' : 'Survey updated',
			type: 'success',
			placement: 'top',
		});
		return newSurvey;
	},

	reset: () => set(initialState),
}));
