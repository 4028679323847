import { safeFetchAPI } from '@/api';
import { storage, utils } from '@/helpers';
import { SurveyQuestion } from '@/types';
import { create } from 'zustand';

type SurveyQuestionBuilderStoreState = {
	builderOpen: boolean;
	mode: 'create' | 'edit';
	ogQuestion: SurveyQuestion | undefined;
	question: Partial<SurveyQuestion>;
	allQuestions: Record<string, SurveyQuestion>;
	allQuestionsLoaded: boolean;
};

type SurveyQuestionBuilderStoreActions = {
	createNewQuestion: () => void;
	setQuestion: (question: Partial<SurveyQuestion>) => void;
	resetQuestion: () => void;
	closeBuilder: () => void;
	openBuilder: (question: SurveyQuestion) => void;
	loadAllQuestions: (noCache?: boolean) => Promise<void>;
	cacheQuestions: (question: SurveyQuestion) => void;
};

const initialState: SurveyQuestionBuilderStoreState = {
	builderOpen: false,
	mode: 'create',
	ogQuestion: undefined,
	question: {},
	allQuestions: {},
	allQuestionsLoaded: false,
};

type SurveyQuestionBuilderStore = SurveyQuestionBuilderStoreState & SurveyQuestionBuilderStoreActions;

export const useSurveyQuestionBuilderStore = create<SurveyQuestionBuilderStore>((set, get) => ({
	...initialState,
	setQuestion: (question) => set({ question }),
	resetQuestion: () => set(initialState),
	createNewQuestion: () => set({ question: {}, ogQuestion: undefined, builderOpen: true, mode: 'create' }),
	closeBuilder: () => set({ builderOpen: false }),
	openBuilder: (questionInput) => {
		const question = cleanQuestion(questionInput);
		set({ question, ogQuestion: question, builderOpen: true, mode: 'edit' });
	},
	loadAllQuestions: async (noCache = false) => {
		const allQuestionsCached = storage.get<Record<string, SurveyQuestion>>('survey-builder-all-questions');
		const isCacheEmpty = Object.keys(allQuestionsCached ?? {}).length === 0;
		if (allQuestionsCached && !noCache && !isCacheEmpty) {
			set({ allQuestions: allQuestionsCached, allQuestionsLoaded: true });
			return;
		}

		const [allQuestions, error] = await safeFetchAPI('/surveys/questions/:uid');
		if (error) {
			utils?.toaster?.addToast?.({
				message: error || 'Error loading questions',
				type: 'danger',
				placement: 'top',
			});
			return;
		}
		set({ allQuestions, allQuestionsLoaded: true });
		storage.set('survey-builder-all-questions', allQuestions, 10 * 60 * 1000);
	},
	cacheQuestions: (question: SurveyQuestion) => {
		const allQuestions = get().allQuestions;
		if (question.id) {
			allQuestions[question.id] = question;
		}
		storage.set('survey-builder-all-questions', allQuestions, 10 * 60 * 1000);
	},
}));

const cleanQuestion = (question: SurveyQuestion): SurveyQuestion => {
	// Removing vertical variants
	if (question.type === 'toggle-vertical') {
		return { ...question, type: 'toggle' };
	}
	if (question.type === 'radio-vertical') {
		return { ...question, type: 'radio' };
	}
	return question;
};
